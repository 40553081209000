<template>
    <div class="organization">
        <div class="organization-wrap">
            <el-card style="background-color: #e4efff;" v-if="is_loading">
                <h2 class="mb-30" align="center">{{ $t('data.register.eCertOrganizationSetting') }}</h2>
                <el-form ref="form" :model="form" label-width="200px">
                    <div class="register-form">
                        <el-form-item :label="$t('data.register.organizationName')">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.organizationAddress')">
                                <el-input v-model="form.address"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.country')">
                                <el-select v-model="form.country_id" placeholder="Select" style="width:100%">
                                    <el-option v-for="(item, index) in countryData" :key="index" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.contactNumber')">
                                <div style="display: flex;">
                                    <el-select v-model="form.country_id" placeholder="Select" style="width: 400px;">
                                        <el-option v-for="(item, index) in countryData" :key="index" :label="item.area_code" :value="item.id"></el-option>
                                    </el-select>
                                    <el-input v-model="form.contact_number" style="margin-left: 15px;"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.email')">
                                <el-input v-model="form.email"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.website')">
                                <el-input v-model="form.website"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.organisationType')">
                                <el-select v-model="form.type_id" placeholder="Select" style="width:100%">
                                    <el-option v-for="(item, index) in orgType" :key="index" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.oneContactPerson')">
                                <el-input v-model="form.one_contact_person"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.twoContactPerson')">
                                <el-input v-model="form.two_contact_person"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.organizationLogo')">
                                <image-upload :btnText="{ text:'Upload Logo',url: form.logo}" @imageFile="imageFile"></image-upload>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.campusPicture')">
                                <image-upload :btnText="{ text:'Upload Picture 1',url: form.campus_pictures[0]}" @imageFile="imageFile"></image-upload>
                                <image-upload :btnText="{ text:'Upload Picture 2',url: form.campus_pictures[1]}" @imageFile="imageFile"></image-upload>
                                <image-upload :btnText="{ text:'Upload Picture 3',url: form.campus_pictures[2]}" @imageFile="imageFile"></image-upload>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.registrations')">
                                <file-upload v-for="(item, index) in form.documents" :key="index" :btnText="{index: index, text:'Upload File',url: item}" @imageFile="imageFile"></file-upload>
                                <div class="register-add-btn">
                                    <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" round @click="addFile()">{{ $t('data.register.addFile') }}</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item :label="$t('data.register.about')">
                                <el-input type="textarea" v-model="form.about" rows="5"></el-input>
                            </el-form-item>
                    </div>
                    <div style="text-align: center;">
                        <el-button type="primary" class="mt-20" @click="formUpdate">{{ $t('data.public.update') }}</el-button>
                    </div>
                </el-form>
            </el-card>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import ImageUpload from '../../components/imageUpload/ImageUpload'
import FileUpload from '../../components/fileUpload/FileUpload'
import { getCounties,organizationType,organizationInfo,editOrganization } from '../../api/index'
export default {
    name: "organization",
    components: {
        ImageUpload,
        FileUpload
    },
    data(){
        return {
            orgType:[],
            form:{
                name: '',
                address: '',
                type_id: null,
                country_id: null,
                area_code: '',
                contact_number: '',
                email: '',
                website: '',
                one_contact_person: '',
                two_contact_person: '',
                logo: '',
                campus_pictures: ['','',''],
                documents: [''],
                certification: ['1'],
                about: ''
            },
            countryData: {},
            is_loading: false,
        }
    },
    mounted(){
        this.getCountryData();
    },
    methods: {
        getCountryData(){
            getCounties({}).then( res => {
                this.countryData = res.data;
            })
            organizationType({}).then( res => {
                this.orgType = res.data;
            })
            organizationInfo({}).then( res => {
                this.form.name = res.data.name;
                this.form.address = res.data.address;
                this.form.type_id = res.data.type.id;
                this.form.country_id = res.data.country.id;
                this.form.area_code = res.data.area_code;
                this.form.contact_number = res.data.contact_number;
                this.form.email = res.data.email;
                this.form.website = res.data.website;
                this.form.one_contact_person = res.data.one_contact_person;
                this.form.two_contact_person = res.data.two_contact_person;
                this.form.logo = res.data.logo;
                this.form.campus_pictures = res.data.campus_pictures;
                this.form.documents = res.data.documents;
                this.form.certification = res.data.certification;
                this.form.about = res.data.about;
                this.is_loading = true;
            })
        },
        imageFile(imageFile){
            if(imageFile.text.text === 'Upload Logo'){
                this.form.logo = imageFile.url;
                return;
            } else if(imageFile.text.text === 'Upload Picture 1'){
                this.form.campus_pictures[0] = imageFile.url;
                return;
            } else if(imageFile.text.text === 'Upload Picture 2'){
                this.form.campus_pictures[1] = imageFile.url;
                return;
            } else if(imageFile.text.text === 'Upload Picture 3'){
                this.form.campus_pictures[2] = imageFile.url;
                return;
            } else if(imageFile.text.text === 'Upload File'){
                this.form.documents[imageFile.text.index] = imageFile.url;
                return;
            }
        },
        addFile(){
            this.form.documents.push('');
        },
        formUpdate(){
            if(this.form.country_id){
                this.form.area_code = this.countryData.filter(item => item.id === this.form.country_id)[0].area_code;
            }
            let apiData = this.form;
            editOrganization({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
            })
        }
    }
}
</script>

<style scoped>
.organization-wrap{

}
.organization .image-upload{
    margin: 0px 0px;
}
</style>
