<template>
    <div class="image-upload">
        <el-upload
            class="upload-demo ecert_upload"
            action="#"
            accept=".pdf,.doc,.docx,.xlsx,.xls"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :limit="1"
            :file-list="fileList"
            :auto-upload="false"
        >
            <el-button size="small" round>{{ typeof(btnText) === 'string' ? btnText : btnText.text }}</el-button>
            <template #tip>
                <div class="el-upload__tip">{{ $t('data.public.best') }} PDF, DOC</div>
            </template>
        </el-upload>
    </div>
</template>

<script>
import {ref} from 'vue'
import { uploadFile } from '../../api/index'
export default {
  name: 'fileUpload',
  props: {
      btnText: String | Object
  },
  data(){
    return{
        imageFile: {
            text: this.btnText,
            file: {},
            url: ''
        },
        fileList: []
    }
  },
  components: {
  },
  computed: {
  },
  mounted(){
      if(this.btnText.url){
          this.fileList = [{name: this.btnText.url, url: this.btnText.url}]
      }
  },
  methods:{
      
      handleRemove(file, fileList) {
        this.imageFile.url = "";
        this.imageFile.file = {};
        this.$emit('imageFile', this.imageFile);
        return;
      },

      handleChange(file, fileList){
        let param = new FormData();
        param.append('file', file.raw)
        param.append('type', 'document')
        let apiData = param
        uploadFile({apiData}).then( res => {
            this.imageFile.url = res.data.url;
            this.imageFile.file = file;
            this.$emit('imageFile', this.imageFile)
        })
      }
  }
}
</script>

<style scoped>
.image-upload{
    margin: 10px 0px;
}
.ecert_upload{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
}
.ecert_upload .el-upload__tip{
    margin-top: 0px;
}
</style>
<style>
.image-upload .el-upload-list__item{
    margin-top: 0px !important;
}
.ecert_upload .el-upload-list--text{
    width: 30%;
}
.ecert_upload .el-upload{
    width: 40%;
    text-align: left;
}
.ecert_upload .el-upload__tip{
    width: 30%;
}
</style>
